import { Link } from '@remix-run/react'
import { Button } from '#app/components/ui/button.tsx'
import { Icon } from '#app/components/ui/icon.tsx'
import { trackCTAClick } from '#app/utils/analytics.ts'

export function Close() {
	return (
		<div className="flex max-w-3xl flex-col items-center px-4 py-32">
			<h2 className="mb-8 text-center text-3xl font-bold leading-normal tracking-tight text-foreground sm:text-4xl xl:text-5xl">
				Build wealth, not spreadsheets.
			</h2>
			<div className="mb-16 max-w-sm text-left text-muted-foreground">
				<p className="">Personal wealth management made simple. Finally.</p>
			</div>
			<div className="w-full animate-slide-top text-center [animation-delay:0.5s] [animation-fill-mode:backwards]">
				<Button asChild className="w-full" size="wide" onClick={trackCTAClick}>
					<Link to="https://y5pjft0758o.typeform.com/to/qhwopy8S">
						Grow your wealth
						<Icon name="arrow-right" className="ml-2 font-bold" />
					</Link>
				</Button>
				<p className="mt-3 text-sm font-extralight text-muted-foreground">
					<Icon className="mr-1" name="rocket" />
					First 10 customers get free lifetime access.
				</p>
			</div>
		</div>
	)
}
