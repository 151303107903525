import hero from '#app/assets/hero.webp'
export function HeroShot() {
	return (
		<section className="mt-20 w-full max-w-6xl animate-slide-top px-5 fill-mode-backwards [animation-delay:0.9s] sm:mt-20">
			<div className="flex w-full items-center justify-center rounded-lg border-8 border-muted bg-muted">
				<img
					src={hero}
					loading="eager"
					width={1304}
					height={1027}
					className="rounded"
					alt="A preview of the Know app dashboard"
				/>
			</div>
		</section>
	)
}
