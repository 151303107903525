import profile from '#app/assets/profile.png'
export function Team() {
	return (
		<div className="mt-36 flex max-w-3xl flex-col gap-10 px-5 leading-relaxed lg:gap-12">
			<div className="w-full leading-relaxed text-muted-foreground">
				<div className="float-left mb-4 mr-10 size-32 rounded-md">
					<img src={profile} className="rounded" />
				</div>
				<p className="mb-4 font-medium text-foreground">Hey, it's Dev 👋</p>
				<p className="mb-4">
					In 2020, I rode the retail investor rollercoaster, winning and losing
					thousands on impulse trades and scattered investments from home. Like
					many of us, I had no idea how to manage risk or build a well-balanced
					portfolio.
				</p>
				<p className="mb-4">
					After months of painful guesswork and mental math, I realized
					something: I needed to see my complete financial picture to make
					better decisions.
				</p>
				<p className="mb-4">So, I built Know for three key reasons:</p>
				<ol className="mb-4">
					<li>1. See your wins to stay motivated</li>
					<li>2. Spot dips to avoid impulse spending</li>
					<li>3. Track performance to invest smarter</li>
				</ol>
				<p className="mb-4">
					Today, I never invest without checking my dashboard first. Know helps
					ambitious investors track their complete net worth, make data-driven
					decisions, and stay focused on long-term wealth building.
				</p>
				<p className="mb-4">
					With <strong className="underline decoration-primary">Know</strong>,
					you can start building wealth with clarity and confidence, too.
				</p>
			</div>
		</div>
	)
}
