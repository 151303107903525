import { TrendingDown } from 'lucide-react'
import { Icon } from '#app/components/ui/icon.tsx'

export function Agitation() {
	return (
		<div className="mt-36 w-full max-w-xl px-5 sm:mt-20">
			<section className="flex flex-col items-center gap-8">
				<ul className="w-full space-y-2 rounded-md bg-red-200/40 p-10 text-center text-lg leading-relaxed text-muted-foreground dark:bg-red-300/20 xl:max-w-xl xl:text-xl/6">
					<li>
						Checking <span className="font-semibold text-red-500">every</span>{' '}
						investment app
					</li>
					<li>
						+ Doing sums
						<span className="font-semibold text-red-500"> in your head</span>
					</li>
					<li>
						+ Trading{' '}
						<span className="font-semibold text-red-500">
							{' '}
							based on your mood
						</span>
					</li>
					<li>
						+ <span className="font-semibold text-red-500">Hoping</span> it will
						work out
					</li>
					<li className="flex justify-center">
						<TrendingDown className="size-8" />
					</li>
				</ul>
				<p className="pt-2 text-sm text-muted-foreground">
					<Icon size="md" className="mr-2" name="arrow-down" />
					There's an easier way
				</p>
			</section>
		</div>
	)
}
