import { Agitation } from './components/Agitation.tsx'
import { Close } from './components/Close.tsx'
// import { FAQs } from './components/FAQs.tsx'
import { HeroShot } from './components/HeroShot.tsx'
import { Open } from './components/Open.tsx'
import { Pricing } from './components/Pricing.tsx'
import { Solution } from './components/Solution.tsx'
import { Team } from './components/Team.tsx'
import { Testimonial } from './components/Testimonial.tsx'

export default function Index() {
	return (
		<main className="font-poppins grid h-full place-items-center xl:pt-14">
			<Open />
			<HeroShot />
			<section className="mt-36 animate-slide-top fill-mode-backwards [animation-delay:1s] sm:mt-20">
				<Testimonial index={0} />
			</section>
			<Agitation />
			<Solution />
			<Team />
			<div className="mt-36 flex w-full flex-col items-center gap-24 bg-secondary/70 pb-36">
				<Pricing />
				<div>
					<Testimonial index={1} light />
				</div>
				{/* <FAQs /> */}
			</div>
			<Close />
		</main>
	)
}
