import {
	Bitcoin,
	ChartCandlestick,
	Gem,
	House,
	Landmark,
	type LucideIcon,
} from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { cn } from '#app/utils/misc.tsx'

type Feature = {
	id: string
	icon: LucideIcon
	label: string
	description: string
}

const features: Feature[] = [
	{
		id: 'crypto',
		icon: Bitcoin,
		label: 'Crypto',
		description: 'Track your entire crypto portfolio in real-time',
	},
	{
		id: 'stocks',
		icon: ChartCandlestick,
		label: 'Stocks',
		description:
			'Monitor your stock performance and allocation across all your trading accounts',
	},
	{
		id: 'funds',
		icon: Landmark,
		label: 'Funds',
		description:
			'See how your fund investments align with your overall wealth strategy',
	},
	{
		id: 'property',
		icon: House,
		label: 'Property',
		description: 'Include your home and investment properties',
	},
	{
		id: 'valuables',
		icon: Gem,
		label: 'Valuables',
		description: 'Complete your portfolio with real-world assets',
	},
]

export function Solution() {
	const [activeFeature, setActiveFeature] = useState<string>(features[0]!.id)
	const [featureClicked, setFeatureClicked] = useState(false)
	const featureIndex = useRef(0)

	useEffect(() => {
		if (featureClicked) return

		const interval = setInterval(() => {
			featureIndex.current = (featureIndex.current + 1) % features.length
			setActiveFeature(features[featureIndex.current]!.id)
		}, 4000)

		return () => clearInterval(interval)
	}, [featureClicked])

	const selectFeature = (featureId: string) => {
		setActiveFeature(featureId)
		setFeatureClicked(true)
	}

	return (
		<div className="mt-24 flex flex-col items-center">
			<section className="flex max-w-3xl flex-col gap-10 px-5 md:px-0">
				<h2 className="text-3xl font-bold leading-normal tracking-tight text-foreground sm:text-4xl xl:text-5xl">
					<span className="underline underline-offset-2">Always</span> know your
					net worth with a dashboard of everything you own.{' '}
				</h2>
				<p className="leading-relaxed text-muted-foreground xl:text-left">
					Make confident moves with a complete view of your wealth. Every asset,
					every investment, one powerful dashboard.
				</p>
			</section>

			<div id="features" className="flex flex-col items-center pt-6 xl:pt-12">
				<div className="flex w-full max-w-3xl flex-wrap items-center justify-between px-5 pb-4 md:px-0">
					{features.map((feature) => (
						<div
							key={feature.id}
							className={cn(
								'flex w-fit cursor-pointer flex-col items-center justify-center gap-2 text-sm font-semibold text-muted-foreground',
								{
									'text-primary': activeFeature === feature.id,
								},
							)}
							onClick={() => selectFeature(feature.id)}
						>
							<feature.icon size={24} />
							{feature.label}
						</div>
					))}
				</div>

				<div className="w-screen items-center bg-secondary/70 px-5 py-8">
					<div className="mx-auto flex min-h-16 max-w-3xl items-center text-left">
						{features.map((feature) =>
							feature.id === activeFeature ? (
								<p
									key={feature.id}
									className="text-muted-foreground duration-700 animate-in fade-in"
								>
									{feature.description}
								</p>
							) : null,
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
