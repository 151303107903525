import { Icon } from '#app/components/ui/icon.tsx'

export function Testimonial({
	index = 0,
	light = false,
}: {
	index: 0 | 1
	light?: boolean
}) {
	const testimonials = [
		{
			name: 'Benjamin Franklin',
			// role: 'Optimal Grip, Founder',
			image: '',
			quote: 'An investment in knowledge pays the best interest.',
		},
		{
			name: 'Peter Drucker',
			// role: 'Exim International, COO',
			image: '',
			quote: 'What gets measured gets managed.',
		},
	]

	if (testimonials[index] === undefined) {
		console.error('Testimonial index out of range')
		return null
	}

	return (
		<section className="text-md relative flex w-full max-w-3xl flex-col gap-3 px-5 text-center leading-relaxed">
			{/* <div className="flex w-full justify-center text-yellow-500">
				<Icon size="lg" name="star-filled" />
				<Icon size="lg" name="star-filled" />
				<Icon size="lg" name="star-filled" />
				<Icon size="lg" name="star-filled" />
				<Icon size="lg" name="star-filled" />
			</div> */}
			<p>{testimonials[index].quote}</p>
			<div className="flex items-center justify-center gap-3">
				{/* <div className="size-12 rounded-full bg-gray-300" /> */}
				<Icon
					name="quote"
					className={`absolute -left-12 -top-20 z-[-1] size-40 scale-x-[-1] ${light ? 'text-primary' : 'text-muted'}`}
				/>
				<div className="flex flex-col text-left">
					<p className="font-semibold">{testimonials[index].name}</p>
					{/* <p className="font-extralight text-muted-foreground">
						{testimonials[index].role}
					</p> */}
				</div>
			</div>
		</section>
	)
}
