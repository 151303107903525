import { Link } from '@remix-run/react'
import { Button } from '#app/components/ui/button.tsx'
import { Icon } from '#app/components/ui/icon.tsx'
import { trackCTAClick } from '#app/utils/analytics.ts'

export function Open() {
	return (
		<section className="mx-5 mt-16 flex max-w-lg flex-col items-center text-center sm:mt-20 lg:max-w-2xl">
			<h1 className="mb-10 animate-slide-top text-4xl font-extrabold leading-normal tracking-tight sm:text-5xl sm:leading-snug lg:mb-12 xl:text-6xl xl:leading-snug">
				Grow your wealth <br />
				<span className="relative whitespace-nowrap">
					<span className="mr-3 sm:mr-4 md:mr-5">with data,</span>
					<span className="relative whitespace-nowrap">
						<span className="absolute -bottom-1 -left-2 -right-2 -top-1 -rotate-1 bg-primary md:-bottom-0 md:-left-3 md:-right-3 md:-top-0"></span>
						<span className="relative dark:text-background">not hope</span>
					</span>
				</span>
			</h1>
			<div className="max-w-md text-left">
				<p className="mb-4 animate-slide-top text-lg font-semibold leading-relaxed [animation-delay:0.3s] [animation-fill-mode:backwards]">
					Actionable insights for everyday investors:
				</p>
				<ul className="mb-8 animate-slide-top space-y-0.5 text-lg leading-relaxed text-muted-foreground [animation-delay:0.5s] [animation-fill-mode:backwards]">
					<li className="flex items-center gap-2">
						<Icon name="check" size="lg" className="text-primary" />
						Track your net worth effortlessly
					</li>
					<li className="flex items-center gap-2">
						<Icon name="check" size="lg" className="text-primary" />
						Balance your asset classes like a pro
					</li>
					<li className="flex items-center gap-2">
						<Icon name="check" size="lg" className="text-primary" />
						Make data-driven decisions that grow your wealth
					</li>
				</ul>
				<div className="w-full animate-slide-top text-center [animation-delay:0.5s] [animation-fill-mode:backwards]">
					<Button
						asChild
						className="w-full"
						size="wide"
						onClick={trackCTAClick}
					>
						<Link to="https://y5pjft0758o.typeform.com/to/qhwopy8S">
							Grow your wealth
							<Icon name="arrow-right" className="ml-2 font-bold" />
						</Link>
					</Button>
					<p className="mt-3 text-sm font-extralight text-muted-foreground">
						<Icon className="mr-1" name="rocket" />
						First 10 customers get free lifetime access.
					</p>
				</div>
			</div>
		</section>
	)
}
