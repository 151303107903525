import { Link } from '@remix-run/react'
import { Badge } from '#app/components/ui/badge.tsx'
import { Button } from '#app/components/ui/button.tsx'
import { Icon } from '#app/components/ui/icon.tsx'
import { trackCTAClick } from '#app/utils/analytics.ts'

export function Pricing() {
	return (
		<div
			id="pricing"
			className="flex w-full max-w-5xl flex-col items-center gap-24 px-5 pt-24 lg:gap-12"
		>
			<div className="flex max-w-3xl flex-col gap-10">
				<h2 className="text-center font-bold text-primary">Pricing</h2>
				<h3 className="text-center text-3xl font-bold leading-normal tracking-tight text-foreground sm:text-4xl xl:text-5xl">
					Leave the chaos behind. Transform your wealth with one powerful
					dashboard. <br />
				</h3>
				<p className="text-center text-sm text-muted-foreground">
					<span className="font-bold text-green-600">
						<Icon size="md" className="mr-1" name="rocket" />
						First 10
					</span>{' '}
					customers get free lifetime access.
				</p>
			</div>
			<div className="flex w-full flex-col gap-8 lg:flex-row lg:gap-10">
				<div className="flex w-full flex-col justify-between gap-5 rounded-md bg-background p-8 text-foreground">
					<p className="text-lg font-bold">Starter</p>
					<div className="relative flex items-end gap-2">
						<p className="relative text-base opacity-80">
							<span className="absolute inset-x-0 top-[47%] h-[1.8px] bg-foreground/80"></span>
							<span className="font-semibold tracking-tight">$19</span>
						</p>
						<div className="flex">
							<p className="text-5xl font-extrabold tracking-tight">$9</p>
							<span className="self-end font-semibold tracking-tight opacity-80">
								/month
							</span>
						</div>
					</div>
					<ul className="space-y-2 leading-relaxed">
						<li className="flex gap-2">
							<Icon name="check" size="md" />
							Real-time portfolio dashboard
						</li>
						<li className="flex gap-2">
							<Icon name="check" size="md" />
							Track unlimited investments & assets
						</li>
						<li className="flex gap-2">
							<Icon name="check" size="md" />
							Performance & allocation insights
						</li>
						<li className="flex gap-2">
							<Icon name="check" size="md" />
							Automatic market updates
						</li>
						<li className="flex gap-2">
							<Icon name="check" size="md" />
							Multi-currency support
						</li>
						<li className="flex gap-2">
							<Icon name="check" size="md" />
							Visual wealth tracking
						</li>
						<li className="flex gap-2 text-muted-foreground/70">
							<Icon size="md" className="p-1" name="cross-1" />
							Deep portfolio analysis & insights
						</li>
						<li className="flex gap-2 text-muted-foreground/70">
							<Icon size="md" className="p-1" name="cross-1" />
							Unlimited custom categories
						</li>
						<li className="flex gap-2 text-muted-foreground/70">
							<Icon size="md" className="p-1" name="cross-1" />
							Weekly personalized wealth reports
						</li>
					</ul>
					<Button
						asChild
						className="w-full px-8 sm:px-24"
						size="wide"
						onClick={trackCTAClick}
					>
						<Link to="https://y5pjft0758o.typeform.com/to/qhwopy8S">
							Grow your wealth
							<Icon name="arrow-right" className="ml-2 font-bold" />
						</Link>
					</Button>
					<p className="text-center text-sm font-medium text-muted-foreground">
						Build wealth, not spreadsheets.
					</p>
				</div>
				<div className="relative flex w-full flex-col justify-between gap-5 rounded-md border-2 border-primary bg-background p-8 text-foreground">
					<Badge className="absolute left-1/2 top-0 w-fit -translate-x-1/2 -translate-y-1/2 text-xs">
						POPULAR
					</Badge>
					<p className="text-lg font-bold">Pro</p>
					<div className="relative flex items-end gap-2">
						<p className="relative text-base opacity-80">
							<span className="absolute inset-x-0 top-[47%] h-[1.8px] bg-foreground/80"></span>
							<span className="font-semibold tracking-tight">$25</span>
						</p>
						<div className="flex">
							<p className="text-5xl font-extrabold tracking-tight">$12</p>
							<span className="self-end font-semibold tracking-tight opacity-80">
								/month
							</span>
						</div>
					</div>
					<ul className="space-y-2 leading-relaxed">
						<li className="flex gap-2">
							<Icon name="check" size="md" className="text-primary" />
							Real-time portfolio dashboard
						</li>
						<li className="flex gap-2">
							<Icon name="check" size="md" className="text-primary" />
							Track unlimited investments & assets
						</li>
						<li className="flex gap-2">
							<Icon name="check" size="md" className="text-primary" />
							Performance & allocation insights
						</li>
						<li className="flex gap-2">
							<Icon name="check" size="md" className="text-primary" />
							Automatic market updates
						</li>
						<li className="flex gap-2">
							<Icon name="check" size="md" className="text-primary" />
							Multi-currency support
						</li>
						<li className="flex gap-2">
							<Icon name="check" size="md" className="text-primary" />
							Visual wealth tracking
						</li>
						<li className="flex gap-2 underline decoration-primary">
							<Icon name="check" size="md" className="text-primary" />
							Deep portfolio analysis & insights
						</li>
						<li className="flex gap-2 underline decoration-primary">
							<Icon name="check" size="md" className="text-primary" />
							Unlimited custom categories
						</li>
						<li className="flex gap-2 underline decoration-primary">
							<Icon name="check" size="md" className="text-primary" />
							Weekly personalized wealth reports
						</li>
					</ul>
					<Button
						asChild
						className="w-full px-8 sm:px-24"
						size="wide"
						onClick={trackCTAClick}
					>
						<Link to="https://y5pjft0758o.typeform.com/to/qhwopy8S">
							Grow your wealth
							<Icon name="arrow-right" className="ml-2 font-bold" />
						</Link>
					</Button>
					<p className="text-center text-sm font-medium text-muted-foreground">
						Build wealth, not spreadsheets.
					</p>
				</div>
			</div>
		</div>
	)
}
